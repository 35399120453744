<template>
  <v-card flat>
    <v-form v-model="valid">
      <template v-for="field in fieldsWithValues">
        <v-col
          v-if="field.show"
          :key="field.key"
          :cols="field.cols ? fields.cols : 12"
        >
          <UiField
            :value="field"
            in-col
            @change="setFieldValue"
          />
        </v-col>
      </template>

      <v-col
        cols="12"
      >
        <v-select
          :label="$t('onlineChannels')"
          v-model="value.channels"
          item-value="uuid"
          item-text="label"
          :items="onlineChannels"
          multiple
          chips
          deletable-chips
          small-chips
          filled
          :disabled="processing"
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-select
          :label="$t('posChannels')"
          v-model="value.posChannels"
          item-value="uuid"
          item-text="label"
          :items="posChannels"
          chips
          multiple
          deletable-chips
          small-chips
          hide-details
          filled
          :disabled="processing"
        />
      </v-col>

      <v-col cols="12" class="mb-0 pb-0">
        <v-select
          :label="$t('categories')"
          v-model="value.categories"
          item-value="uuid"
          item-text="label"
          :items="categories"
          multiple
          chips
          deletable-chips
          small-chips
          filled
          :disabled="processing"
        />
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-center"
      >
        <v-menu
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-text-field
              v-on="on"
              v-bind="attrs"
              :label="$t('primaryColor')"
              :placeholder="$t('clickHereToSelectAColor')"
              v-model="value.primaryColor"
              :disabled="processing"
              persistent-hint
              prepend-icon="mdi-palette"
              hint="Secondary colors are applied automatically"
            >
              <template v-slot:append-outer>
                <v-icon
                  v-if="value.primaryColor"
                  :color="value.primaryColor"
                >
                  mdi-circle
                </v-icon>
                <v-icon v-else>
                  mdi-circle-outline
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="value.primaryColor"
            width="400"
            mode="hexa"
            hide-mode-switch
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
      >
        <v-switch
          v-model="value.useDarkText"
          :label="$t('useDarkText')"
        />
      </v-col>
    </v-form>
  </v-card>
</template>

<script>

import UiField from "@/components/uiField"
import orderStatusDisplays from "@/views/order-status-displays"
export default {
	name: 'OrderStatusDisplayForm',
  components: { UiField },
  props: ['value'],
	data() {
		return {
      initialColor: null,
			processing: false,
      orderStatusDisplay: null,
      fieldsWithValues: null,
      valid: false,
		}
	},
  mounted() {
    this.orderStatusDisplay = this.value
    if( typeof this.orderStatusDisplay !== 'undefined' && this.orderStatusDisplay !== null ) {
      this.setFieldValues()
      this.initialColor = this.orderStatusDisplay.primaryColor
    }
  },
	computed: {
    fields() {
      return [
        {
          key: 'title',
          show: true,
          value: null,
          type: 'text',
          label: this.$t('title'),
          required: true,
          rules: [this.rules.required],
          editable:  true,
          clearable: true,
          persistentHint: true,
          hint: this.$t('logoInsteadTitle')
        },
        {
          key: 'location',
          show: true,
          value: null,
          type: 'select',
          label: this.$t('location'),
          required: true,
          rules: [this.rules.required],
          multiple: false,
          options: this.locations
        },
      ]
    },
    categories() {
      return this.$store.state.categories.map(c => {
        return {
          uuid: c.uuid,
          label: c.name
        }
      })
    },
    rules() {
      return this.$store.getters.rules
    },
    channels() {
      return this.$store.state.channels
    },
		posChannels() {
			if(Array.isArray(this.channels)) {
				if(this.orderStatusDisplay && Array.isArray(this.orderStatusDisplay.channels)) {
					return this.channels.map(c => {
						return {
							uuid: c.uuid,
							label: c.label,
							disabled: this.orderStatusDisplay.channels.indexOf(c.uuid) !== -1 && this.orderStatusDisplay.posChannels.indexOf(c.uuid) === -1
						}
					})
				}
				return this.channels
			}
			return []
		},
		onlineChannels() {
			if(Array.isArray(this.channels)) {
				if(this.orderStatusDisplay && Array.isArray(this.orderStatusDisplay.posChannels)) {
					return this.channels.map(c => {
						return {
              uuid: c.uuid,
              label: c.label,
							disabled: this.orderStatusDisplay.channels.indexOf(c.uuid) === -1 && this.orderStatusDisplay.posChannels.indexOf(c.uuid) !== -1
						}
					})
				}
				return this.channels
			}
			return []
		},
    locations() {
      const locations = Array.isArray(this.$store.state.locations) ? this.$store.state.locations.filter(location => location && location.active !== false) : []
      return this.$store.getters.locationsFormatForUiFields(locations)
    },
	},
	methods: {
    setFieldValue({ field, value }) {
      this.orderStatusDisplay[field.key] = value
    },
    setFieldValues() {
      const newFields = []
      for(const index in this.fields) {
        const field = this.fields[index]
        if(field && field.key) {
          if(typeof this.orderStatusDisplay[field.key] !== 'undefined') {
            field.value = this.orderStatusDisplay[field.key]
            this.fields[index].value = this.orderStatusDisplay[field.key]
          }
        }
        newFields.push(field)
      }
      this.fieldsWithValues = newFields
    },
	},
  watch: {
    valid(value) {
      this.$emit('valid', value)
    },
  }
}
</script>
